import $ from "jquery"

function init() {}
function ready() {
  !(function () {
    document.querySelectorAll('a[href^="#"]').forEach((e) => {
      e.addEventListener("click", s)
    })
    const c = document.querySelectorAll(".p-tab-link__link"),
      i = location.pathname
    c.forEach((e) => {
      e.getAttribute("href") === i && e.classList.add("is-active")
    })
  })()
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
