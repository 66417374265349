import $ from "jquery"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import CustomEase from "gsap/CustomEase"

const init = () => {
  gsap.registerPlugin(ScrollTrigger, CustomEase)
}

const ready = () => {
  const mm = gsap.matchMedia()
  const target = document.querySelector(".top-heading")
  if (target) {
    const heightControl = () => {
      $(".bg-scroll").css("height", $("#strategy").height())
      $(window).on("resize", () => {
        $(".bg-scroll").css("height", $("#strategy").height())
        $(".bg-scroll-row").css("display", "flex")
      })
      $(".bg-scroll-row").css("display", "flex")
    }
    const fadeIn = () => {
      gsap.set(".bg-scroll-row", { opacity: 0, display: "none" })
      gsap.to(".bg-scroll-row", {
        ease: "power2.out",
        opacity: 1,
        display: "flex",
        scrollTrigger: {
          trigger: ".top-heading",
          start: "top 80%",
          end: "center center",
          scrub: true,
        },
      })
    }

    const fadeOut = () => {
      gsap.set(".bg-scroll-row", { opacity: 1, display: "flex" })
      gsap.to(".bg-scroll-row", {
        ease: CustomEase.create(
          "custom",
          "M0,0,C0.532,0.07,0.798,0.377,0.882,0.528,0.952,0.654,0.988,0.812,1,1"
        ),
        opacity: 0,
        display: "flex",
        scrollTrigger: {
          trigger: ".top-heading",
          start: "center center",
          end: "90% top",
          scrub: true,
        },
      })
    }

    const scroll = () => {
      const bgScroll = gsap.timeline({
        scrollTrigger: {
          trigger: ".top-heading",
          start: "top center",
          end: "bottom top",
          scrub: 2,
        },
      })
      bgScroll.to(".bg-scroll1", { y: -100 })
      bgScroll.to(".bg-scroll2", { y: -200 }, "<")
      bgScroll.to(".bg-scroll3", { y: -300 }, "<")
    }
    heightControl()
    mm.add("(min-width: 768px)", () => {
      fadeIn()
      fadeOut()
      scroll()
    })
  }
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
