import $ from "jquery"
import Swiper from "swiper/swiper-bundle.esm"

const init = () => {}

const options = {
  loop: false,
  centeredSlides: false,
  allowTouchMove: true,
  allowScroll: true,
  slidesPerView: 1,
  spaceBetween: 16,
  speed: 600,
  breakpoints: {
    1200: {
      slidesPerView: 3,
    },
    767: {
      slidesPerView: 2,
    },
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: true,
  },
}

const ready = () => {
  const target = document.querySelector(".js-blog-swiper")
  if (target !== null) {
    new Swiper(".js-blog-swiper", options)
  }
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
