// Tabコンポーネント用のJSファイル

import getUniqueStrId from '../_helper/_getUniqueStrId'

// タブリストウィジェットを実装するクラス
class TabsAutomatic {
  // コンストラクタ
  constructor(groupNode) {
    this.tablistNode = groupNode

    this.tabs = []

    this.firstTab = null
    this.lastTab = null

    // タブリストからすべてのタブを取得
    this.tabs = Array.from(this.tablistNode.querySelectorAll('[role=tab]'))

    this.tabpanels = []

    let initialTab = null
    const urlParams = new URLSearchParams(window.location.search)
    const initialTabIds = urlParams.getAll('initialTab')

    // タブとタブパネルにIDを設定
    this.setInitId()

    // タブとタブパネルの関連付けと初期化
    for (let i = 0; i < this.tabs.length; i += 1) {
      const tab = this.tabs[i]
      const tabpanel = document.getElementById(
        tab.getAttribute('aria-controls')
      )

      // タブのキーボードフォーカスを無効にする
      tab.tabIndex = -1

      // URLパラメータに基づいて初期タブを設定
      if (initialTabIds.includes(tab.id)) {
        initialTab = tab
      }
      if (!initialTab && tab.getAttribute('aria-selected') === 'true') {
        initialTab = tab
      }

      // タブのaria-selected属性をfalseに設定し、関連するタブパネルを非表示にする
      tab.setAttribute('aria-selected', 'false')
      this.tabpanels.push(tabpanel)

      // イベントリスナーの追加
      tab.addEventListener('keydown', this.onKeydown.bind(this))
      tab.addEventListener('click', this.onClick.bind(this))

      // 最初のタブと最後のタブを設定
      if (!this.firstTab) {
        this.firstTab = tab
      }
      this.lastTab = tab
    }

    // 初期タブが設定されている場合は選択状態にする
    if (initialTab) {
      this.setSelectedTab(initialTab, false)
    } else {
      this.setSelectedTab(this.firstTab, false)
    }
  }

  // タブとタブパネルに一意のIDを設定する関数
  setInitId() {
    for (let index = 0; index < this.tabs.length; index++) {
      const btnId = getUniqueStrId('tab-btns')
      const tabpanel = document.getElementById(
        this.tabs[index].getAttribute('aria-controls')
      )

      if (!this.tabs[index].id) {
        this.tabs[index].id = btnId
        tabpanel.setAttribute('aria-labelledby', btnId)
      }
    }
  }

  // 選択されたタブを設定する関数
  setSelectedTab(currentTab, setFocus = true) {
    if (typeof setFocus !== 'boolean') {
      setFocus = true
    }
    for (let i = 0; i < this.tabs.length; i += 1) {
      const tab = this.tabs[i]

      // 選択されたタブの属性を更新し、関連するタブパネルを表示する
      if (currentTab === tab) {
        tab.setAttribute('aria-selected', 'true')
        tab.removeAttribute('tabindex')
        this.tabpanels[i].hidden = false
        if (setFocus) {
          tab.focus()
        }
      } else {
        tab.setAttribute('aria-selected', 'false')
        tab.tabIndex = -1
        this.tabpanels[i].hidden = true
      }
    }
  }

  // 無効ではないタブのリストを取得する関数
  getNonDisabledTabList() {
    return this.tabs
      .map((tab, index) => {
        return {
          isDisabled: tab.disabled,
          index: index,
        }
      })
      .filter((tab) => !tab.isDisabled)
  }

  // 次のインデックスを取得する関数
  getNextIndex(currentTab, isNext) {
    const index = this.tabs.indexOf(currentTab)
    const maxIndex = this.tabs.length - 1
    let nextIndex = -1
    let isNextIndex = false
    let cntNum = 0

    if (isNext) {
      nextIndex = currentTab === this.lastTab ? 0 : index + 1
    } else {
      nextIndex = currentTab === this.firstTab ? maxIndex : index - 1
    }

    // 次の有効なタブインデックスを取得するループ
    while (cntNum < maxIndex) {
      if (!this.tabs[nextIndex].disabled) {
        isNextIndex = true
        break
      }

      if (isNext) {
        nextIndex = this.tabs[nextIndex] === this.lastTab ? 0 : ++nextIndex
      } else {
        nextIndex =
          this.tabs[nextIndex] === this.firstTab ? maxIndex : --nextIndex
      }

      cntNum++
    }

    if (!isNextIndex) {
      nextIndex = -1
    }

    return nextIndex
  }

  // 前のタブを選択する関数
  setSelectedToPreviousTab(currentTab) {
    const nextIndex = this.getNextIndex(currentTab, false)
    if (nextIndex !== -1) {
      this.setSelectedTab(this.tabs[nextIndex])
    }
  }

  // 次のタブを選択する関数
  setSelectedToNextTab(currentTab) {
    const nextIndex = this.getNextIndex(currentTab, true)
    if (nextIndex !== -1) {
      this.setSelectedTab(this.tabs[nextIndex])
    }
  }

  // 最初のタブを選択する関数
  setSelectedToFirstTab() {
    const nonDisabledTabList = this.getNonDisabledTabList()
    if (nonDisabledTabList.length) {
      this.setSelectedTab(this.tabs[nonDisabledTabList[0].index])
    }
  }

  // 最後のタブを選択する関数
  setSelectedToLastTab() {
    const nonDisabledTabList = this.getNonDisabledTabList()
    if (nonDisabledTabList.length) {
      this.setSelectedTab(
        this.tabs[nonDisabledTabList[nonDisabledTabList.length - 1].index]
      )
    }
  }

  // キーボード操作に対応するイベントハンドラ
  onKeydown(event) {
    const tgt = event.currentTarget
    let flag = false
    switch (event.key) {
      case 'ArrowLeft':
        this.setSelectedToPreviousTab(tgt)
        flag = true
        break

      case 'ArrowRight':
        this.setSelectedToNextTab(tgt)
        flag = true
        break

      case 'Home':
        this.setSelectedToFirstTab()
        flag = true
        break

      case 'End':
        this.setSelectedToLastTab()
        flag = true
        break

      default:
        break
    }

    // イベントの伝播とデフォルトの動作を止める
    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  // タブのクリックイベントハンドラ
  onClick(event) {
    this.setSelectedTab(event.currentTarget)
  }
}

// タブリストの初期化を行う関数
function ready() {
  window.addEventListener('DOMContentLoaded', function () {
    const tablists = document.querySelectorAll(
      '[role=tablist]:not(.is-initialized)'
    )
    for (let i = 0; i < tablists.length; i++) {
      new TabsAutomatic(tablists[i])
      tablists[i].classList.add('is-initialized')
    }
  })
}

export default {
  init() {
    ready()
  },
}
