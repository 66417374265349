import $ from "jquery"
import Splide from "@splidejs/splide"

function init() {}
function ready() {
  const target = document.querySelector(".js-top-mainvisual-slider")

  const options = {
    mediaQuery: "max",
    perPage: 1,
    perMove: 1,
    gap: 0,
    type: "fade",
    rewind: true,
    autoplay: "true",
    interval: 5000,
    speed: 1000,
    pauseOnHover: false,
    pauseOnFocus: false,
    arrows: false,
    pagination: true,
    breakpoints: {
      767: {},
    },
  }

  if (target) {
    const topMainvisualSlider = new Splide(target, options)

    // ページネーションのHTML調整
    topMainvisualSlider.on("pagination:mounted", (data) => {
      // プログレスバーの幅と間隔を画面幅とスライド数から計算
      const bar_width = ($(".l-container").width() * 0.5) / data.items.length
      $(".p-mainvisual__controllers .splide__pagination__page").css(
        "width",
        bar_width * 0.9
      )
      $(".p-mainvisual__controllers .splide__pagination ").css(
        "gap",
        bar_width * 0.1
      )
      // プログレスバーを追加
      data.items.forEach(function (item) {
        const childEl = document.createElement("div")
        childEl.classList.add("progressbar")
        item.button.appendChild(childEl)
      })
    })

    // スライドがアクティブになった時の処理
    topMainvisualSlider.on("active", () => {
      const activeEl = $(
        ".p-mainvisual__controllers .splide__pagination__page.is-active"
      ).find(".progressbar")
      const nonActiveEl = $(
        ".p-mainvisual__controllers .splide__pagination__page"
      )
        .not(".is-active")
        .find(".progressbar")
      activeEl.animate({ width: "100%" }, 5000, "linear", () => {
        nonActiveEl.css("width", "0")
      })
    })

    // スライドの自動再生がストップされたときの処理
    topMainvisualSlider.on("autoplay:pause", () => {
      const activeEl = $(
        ".p-mainvisual__controllers .splide__pagination__page.is-active"
      ).find(".progressbar")
      activeEl.stop()
      activeEl.css("width", "100%")
    })

    // スライドの自動再生が再開されたときの処理
    topMainvisualSlider.on("autoplay:play", () => {
      const activeEl = $(
        ".p-mainvisual__controllers .splide__pagination__page.is-active"
      ).find(".progressbar")
      const nonActiveEl = $(
        ".p-mainvisual__controllers .splide__pagination__page"
      )
        .not(".is-active")
        .find(".progressbar")
      activeEl.stop()
      activeEl.css("width", "0")
      activeEl.animate({ width: "100%" }, 4990, "linear", () => {
        nonActiveEl.css("width", "0")
      })
    })

    // スライドがクリックされたときの処理
    topMainvisualSlider.on("click", () => {
      const activeEl = $(
        ".p-mainvisual__controllers .splide__pagination__page.is-active"
      ).find(".progressbar")
      const nonActiveEl = $(
        ".p-mainvisual__controllers .splide__pagination__page"
      )
      activeEl.stop()
      activeEl.css("width", "0")
      activeEl.animate({ width: "100%" }, 4990, "linear", () => {
        nonActiveEl.css("width", "0")
      })
    })

    topMainvisualSlider.mount()
  }
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
