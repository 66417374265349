// IntersectionObserverを使用して特定の要素（.js-scroll-anim）が画面内に表示されたときに._animを付与する

import $ from 'jquery'

function init() {}
function ready() {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.2,
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('_anim')
        observer.unobserve(entry.target)
      }
    })
  }, options)

  const targets = document.querySelectorAll('.js-scroll-anim')

  targets.forEach((target) => {
    observer.observe(target)
  })
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
