import $ from "jquery"
import Splide from "@splidejs/splide"
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll"

function init() {}
function ready() {
  const target = document.querySelector(".js-top-bg-slider")
  const options = {
    type: "loop",
    focus: "center",
    arrows: false,
    pagination: false,
    drag: false,
    fixedWidth: "500px",
    autoScroll: {
      pauseOnHover: false,
      speed: 0.4,
    },
    breakpoints: {
      767: {
        fixedWidth: "250px",
        fixedheight: "250px",
      },
    },
  }

  if (target !== null) {
    const topBgSlider = new Splide(target, options)
    topBgSlider.mount({ AutoScroll })
  }
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
