// match-mediaを管理するファイル

const matchMedia = {
  '--sm': window.matchMedia('(min-width: 576px)'),
  '--md': window.matchMedia('(min-width: 768px)'),
  '--lg': window.matchMedia('(min-width: 1024px)'),
  '--xl': window.matchMedia('(min-width: 1200px)'),
  '--xxl': window.matchMedia('(min-width: 1400px)'),

  '--to-sm': window.matchMedia('(max-width: 575px)'),
  '--to-md': window.matchMedia('(max-width: 767px)'),
  '--to-lg': window.matchMedia('(max-width: 1023px)'),
  '--to-xl': window.matchMedia('(max-width: 1199px)'),

  '--sm-md': window.matchMedia('(min-width: 576px) and (max-width: 767px)'),
  '--md-lg': window.matchMedia('(min-width: 768px) and (max-width: 1023px)'),
  '--lg-xl': window.matchMedia('(min-width: 1024px) and (max-width: 1199px)'),
  '--xl-xxl': window.matchMedia('(min-width: 1200px) and (max-width: 1399px)'),

  '--no-match': window.matchMedia('(max-width: 0px)'),
  '--all-match': window.matchMedia('(min-width: 0px)'),
}

export default matchMedia
