// スクロールバーの幅をCSS変数に設定する

import { throttle } from 'throttle-debounce'

let defOverflowValue = '' // 初期状態のオーバーフロー値を格納する変数
let defScrollbarWidth = '' // 初期状態のスクロールバーの幅を格納する変数

function getScrollbarWidth() {
  // ウィンドウの幅からドキュメント要素の幅を引いた値を返す関数
  const r = window.innerWidth - document.documentElement.clientWidth
  return r
}

function setScrollbarWidth() {
  // スクロールバーの幅を取得し、CSS変数「--scrollbar-width」に設定する関数
  const w = getScrollbarWidth()
  document.documentElement.style.setProperty('--scrollbar-width', w + 'px')
}

function checkScrollbarWidth() {
  // ウィンドウのオーバーフロー値とスクロールバーの幅を比較し、変更があればsetScrollbarWidth関数を呼び出す関数
  const overflowValue = window.document.documentElement.style.overflowY
  const scrollbarWidth = getScrollbarWidth()

  if (
    defOverflowValue !== overflowValue ||
    defScrollbarWidth !== scrollbarWidth
  ) {
    setScrollbarWidth()
    defOverflowValue = overflowValue
    defScrollbarWidth = scrollbarWidth
  }
}

function init() {
  // ページの初期化時に実行される関数。スクロールバーの幅を設定し、MutationObserverとイベントリスナーを設定する
  setScrollbarWidth()
  const observer = new MutationObserver(throttle(250, checkScrollbarWidth))
  observer.observe(document.documentElement, {
    attributes: true, // ドキュメント要素の属性の変更を監視
    subtree: true, // ドキュメント要素のサブツリーの変更を監視
  })
  window.addEventListener('scroll', throttle(250, checkScrollbarWidth), false) // スクロールイベントのリスナーを設定
  window.addEventListener('resize', throttle(250, checkScrollbarWidth), false) // リサイズイベントのリスナーを設定
}

export default {
  init() {
    init()
  },
}
