import $ from 'jquery'
import component from '../_import/_component'

/**
 * Focus Loopを行うコンテンツを格納する
 * focusLoopStackにデータがある場合のみtabキーによるフォーカスをターゲットコンテンツ内に閉じ込める
 * Escキーでターゲットコンテンツを閉じる処理を行う
 */
// Focus Loop用のデータを格納するスタック
let focusLoopStack = []
/*
{
  id: string
  type: string
  targetEl: HTMLElement
  openBtnEl: HTMLElement
  afterFocusEl: HTMLElement
  addFocus: {
    el: HTMLElement
    matchMedia: --to-md | --md | --lg | etc
  } <Array>
  closeFunction: function
}
*/

// フォーカスを移動するための関数
const focusMove = function (e) {
  const focusLoop = focusLoopStack[focusLoopStack.length - 1]
  const $currentTarget = $(focusLoop.targetEl)
  const $currentTargetFocusable = $currentTarget.find(':_focusable')
  let $currentTargetFocusableAdd = $currentTargetFocusable

  if ($currentTarget[0]) {
    // ターゲットコンテンツ以外にフォーカスを回す要素を追加する
    if (focusLoop.addFocus && focusLoop.addFocus.length) {
      for (let index = 0; index < focusLoop.addFocus.length; index++) {
        const $el = $(focusLoop.addFocus[index].el)
        if ($el[0]) {
          // メディアクエリでaddFocusを切り替える
          if (focusLoop.addFocus[index].matchMedia) {
            if (
              component.matchMedia[focusLoop.addFocus[index].matchMedia] &&
              component.matchMedia[focusLoop.addFocus[index].matchMedia].matches
            ) {
              $currentTargetFocusableAdd = $currentTargetFocusableAdd.add($el)
            }
          } else {
            $currentTargetFocusableAdd = $currentTargetFocusableAdd.add($el)
          }
        }
      }
    }

    const focusLen = $currentTargetFocusableAdd.length
    const thisFocusIndex = $currentTargetFocusableAdd.index($(':focus'))
    let next = thisFocusIndex

    const $firstEl = $currentTargetFocusableAdd.first()
    const $lastEl = $currentTargetFocusableAdd.last()
    const activeElement = document.activeElement

    // Chromeでクリック後のTabキーでfocus()制御を行うと:focus-visible判定がでないので対処
    // ただし、外部フォーカス要素からターゲット内に移動するときはfocus()が必要になり、初回は:focus-visible判定
    // どうやらクリック後、e.preventDefaultさせずにキー操作を通す必要がある？

    // Shiftキーが押された場合は前の要素にフォーカスを移動
    if (e.shiftKey) {
      next--
      next = next < 0 ? focusLen - 1 : next

      // prevはすべてfocus()対応
      if ($currentTargetFocusableAdd[next]) {
        e.preventDefault()
        $currentTargetFocusableAdd[next].focus()
      }
    } else if (!e.shiftKey) {
      // Shiftキーが押されていない場合は次の要素にフォーカスを移動
      next++
      next = next >= focusLen ? 0 : next

      // nextは$currentTarget外ならfocus()
      if (
        !$currentTargetFocusable.filter((index, el) => el === activeElement)[0]
      ) {
        // 外側
        if ($currentTargetFocusableAdd[next]) {
          // chrome, 初回のこのタイミングは:focus-visible判定がでない
          e.preventDefault()
          $currentTargetFocusableAdd[next].focus()
        }
      } else {
        // $currentTarget内、且つ最終要素以外なら制御無し
        if ($lastEl[0] === activeElement && !e.shiftKey) {
          e.preventDefault()
          $firstEl[0].focus()
        }
      }
    }
  }
}

// ページ上でキーが離された時に呼ばれる関数
$(document).on('keyup.focusLoopStack', function () {})
// ページ上でキーが押された時に呼ばれる関数
$(document).on('keydown.focusLoopStack', function (e) {
  if (focusLoopStack.length) {
    const focusLoop = focusLoopStack[focusLoopStack.length - 1]
    if (e.key === 'Tab') {
      focusMove(e)
    }
    if (e.key === 'Escape') {
      e.preventDefault()
      if (
        focusLoop &&
        focusLoop.closeFunction &&
        typeof focusLoop.closeFunction === 'function'
      ) {
        focusLoop.closeFunction()
      }
    }
  }
})

export default {
  stack: focusLoopStack,
  delById: function (id) {
    for (let index = 0; index < focusLoopStack.length; index++) {
      const element = focusLoopStack[index]
      if (element.id === id) {
        focusLoopStack.splice(index, 1)
        break
      }
    }
  },
  getAndDelById: function (id) {
    let ret = []
    for (let index = 0; index < focusLoopStack.length; index++) {
      const element = focusLoopStack[index]
      if (element.id === id) {
        ret = focusLoopStack.splice(index, 1)
        break
      }
    }
    return ret.length ? ret[0] : null
  },
  getTypeLength: function (typeName) {
    let ret = 0
    for (let index = 0; index < focusLoopStack.length; index++) {
      const element = focusLoopStack[index]
      if (element.type === typeName) {
        ret++
      }
    }
    return ret
  },
}
