import $ from "jquery"

function init() {}
function ready() {
  $(function () {
    const headNav = $(".page-top .js-header-fixed")
    $(window).scroll(function () {
      // スクロール量が200px以上かつクラスfixedが付与されていないとき
      if ($(this).scrollTop() > 200 && headNav.hasClass("_active") == false) {
        //headerの高さ分上に設定（上から下りてくる動きにするため）
        headNav.css({ top: "-100px" })
        //クラスfixedを付与
        headNav.addClass("_active")
        //位置を0に設定し、上から下りてくるスピードを設定
        headNav.animate({ top: 0 }, 400)
      }
      // スクロール量が200px以下かつクラスfixedが付与されているとき
      else if (
        $(this).scrollTop() < 200 &&
        headNav.hasClass("_active") == true
      ) {
        // クラスfixedを外す
        headNav.removeClass("_active")
      }
    })
  })
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
